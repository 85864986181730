/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-base text-xl md:text-2xl text-c_1-600 font-semibold;
  }

  h3 {
    @apply text-base text-xl md:text-2xl text-c_1-500 font-semibold;
  }
  p {
    @apply font-sans text-xs text-base md:text-xl text-c_4-900 font-semibold;
  }
  a {
    @apply font-sans text-xs text-base md:text-xl text-c_1-600;
  }
  .bla {
    @apply m-0 p-0 w-full relative;
  }
  .footer {
    @apply h-8 h-8 md:h-16 object-center mx-4 my-2;
  }
  .zusammenarbeit_logo {
    @apply h-16 h-16 md:h-24 object-center mx-10 my-2;
  }
}
